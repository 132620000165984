@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');

body {
	font-family: 'Cabin', sans-serif;
}

/*SLIDER BACKGRUND AND ANIMATION*/
.slick-slider .slide-img-bg {
  opacity: 1;
  background-color: #fff;
}

.slide-img-bg {
  background-image: url("~assets/utils/images/login/1-1.jpg");
  background-size: auto;
  background-position: -230px 0;
}
.slick-slider .slide-img-bg-register {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 1;
  background-color: #fff;
  width: 100%;
  height: 100%;
  background-size: cover;
  z-index: 10;	
}
.slide-img-bg-register {
  background-image: url("~assets/utils/images/register/register.jpg");
  background-size: auto;
  background-position: -120px 0;
  
}
#cloud-1 {
  width: 148px;
  height: 98px;
  top: 15%;
  display: block;
  position: absolute;
  background-image: url("~assets/utils/images/login/cloud-1.png");
  background-size: auto;
  animation: move_clouds_1 35s linear infinite;
  z-index: 12;
}

#cloud-2 {
  width: 186px;
  height: 127px;	
  background-image: url("~assets/utils/images/login/cloud-2.png");
  background-size: auto;
  top: 20%;
  display: block;
  position: absolute;
  animation: move_clouds_2 25s linear infinite;
  z-index: 11;
}

#cloud-3 {
  width: 281px;
  height: 194px;
  background-image: url("~assets/utils/images/login/cloud-3.png");
  background-size: auto;
  top: 2%;
  display: block;
  position: absolute;
  animation: move_clouds_3 45s linear infinite;
  z-index: 10;
}

@keyframes move_clouds_1 {
  0% {
    left: -190px;
  }

  100% {
    left: 100%;
  }
}

@keyframes move_clouds_2 {
  0% {
    right: -250px;
  }

  100% {
    right: 100%;
  }
}

@keyframes move_clouds_3 {
  0% {
    left: -700px;
  }

  100% {
    left: 100%;
  }
}

.no-padding {
  padding: 0rem;
}
/*END SLIDER BACKGRUND AND ANIMATION*/

/*LOGO*/
.app-logo {
  height: 84px;
  width: 250px;
  background: url("~assets/utils/images/logo-2.png");
  background-repeat: no-repeat;
  background-size: auto 100%;
  margin: auto;
  top: -120px;
}

/*PLACEHOLDER STYLE*/

.form-control {
  &::-webkit-input-placeholder, &::-moz-placeholder, &:-ms-input-placeholder, &::-ms-input-placeholder, &::placeholder {
    color: #b0adad;
  }
}
/*FORM FIELDS/BUTTON STYLE*/

.form-control {
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  height: auto;
  border: 1px solid #42accd;
  border-radius: 3px;
  line-height: 1;
  padding: 15px 20px 15px 20px;

  &:focus {
    box-shadow: 0px 0em 0.5em #42accd !important;
  }
}
.form-style {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  padding: 22px 20px 22px 20px !important;
  color: #495057;
  background-color: #fff;
  border: 1px solid #42accd;
  border-radius: 3px;

  &:focus {
    box-shadow: 0px 0em 0.5em #42accd;
  }
}
/*BTN PRIMARY CUSTOM*/

.btn-primary-custom {
  color: #fff;
  font-size: 16px;
  background: linear-gradient(to bottom, #6fc7e2 50%, #42accd 50%) no-repeat scroll right (bottom / 100%) 210% #42accd !important;
  overflow: hidden;
  border: none !important;
  border-radius: 3px;
  padding: 15px 30px 15px 30px !important;
  transition: all 0.3s ease;

  &:hover {
    background-position: right top !important;
    color: #ffffff !important;
    transition: all 0.3s ease;
  }
}

.btn-xs {
  font-size: 11px;
  padding: 2px 5px;
}
.p-forgot {
  padding-left: 0 !important;
  position: absolute;
  left: 0;
  padding-left: 20px !important;
  color: #0f0539;

  &:hover {
    color: #42accd;
    text-decoration: none;
  }
}
.sign-up {
  color: #42accd;
  transition: all 0.3s ease;
  &:hover {
    color: #0f0539;
    text-decoration: none;
	transition: all 0.3s ease;
  }
}

.space {
	padding: 10px;
}
.footer-login {
  position: absolute;
  bottom: 0;
}
/*CHECKBOX STYLE*/

.checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  margin-left: 0px !important;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
}

.checkmark {
  position: absolute;
  top: -2px;
  left: 0;
  height: 22px;
  width: 22px;
  border-radius: 2px;
  background-color: #fff;
  border: 1px solid #42accd;
}

.checkbox input:checked ~ .checkmark {
  background-color: #42accd;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox {
  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

/*END CHECKBOX STYLE*/
@media only screen and (max-width: 1400px) {
/*LOGO*/
.app-logo {
  height: 67px;
  width: 200px;
}	
.form-control {
    font-size: 13px;
    padding: 14px 8px 14px 10px !important;
  }
.btn-primary-custom {
    font-size: 14px;
    padding: 14px 30px 14px 30px !important;
  }

}
@media only screen and (max-width: 600px) {
.alredy {
	display: block !important;
	
}	
.alredy-h-a {
	margin-bottom: 30px !important;	
}	
	
}

.ladda-label{
 z-index: 0!important; 
}

.info-group{
  padding: 20px;
  margin-bottom: 15px;
  box-shadow: 0 0.46875rem 2.1875rem rgba(3, 10, 13, 0.03), 0 0.9375rem 1.40625rem rgba(3, 10, 13, 0.03), 0 0.25rem 0.53125rem rgba(3, 10, 13, 0.05), 0 0.125rem 0.1875rem rgba(3, 10, 13, 0.03);
}

.info-block{
  display: flex;
  justify-content: space-between;
  padding: 10px;
  &:hover{
    background-color: whitesmoke;
  }
}

.info-block span {
  display: inline-block;
}

.info-title{
  text-transform: uppercase;
  font-size: 17px;
  font-weight: 700;
}

.info-data{
  font-size: 15px;
  max-width: 50%;
}

.bg-whitesmoke{
  background-color: whitesmoke;
}

.input-primary{
  color: #42accd;
  font-weight: bold;
}

.not-form-input{
  position: static;
  margin: 0;
}

.cell-collapsed{
  width: 1px;
  white-space: nowrap;
}

.padding-5{
  padding: 5px !important;
}

.no-border tr td {
  border-top: 0;
}

.hover-whitesmoke:hover{
  background-color: whitesmoke;
}

.homepage-wrapper{
  cursor: pointer;
}

.page-title-wrapper{
  justify-content: space-between !important;
}

.widget-progress-wrapper {
  margin-top: $widget-spacer;

  .progress-sub-label {
    margin-top: ($widget-spacer / 3);
    display: flex;
    align-content: center;
    align-items: center;

    .sub-label-left {

    }

    .sub-label-right {
      margin-left: auto;
    }
  }
}

.report-wrapper{
  border: 1px solid rgba(162, 167, 170, 0.2);
  border-radius: 5px;
  padding: 10px;
  margin: 0px 10px;
  &:hover{
    border: 1px solid rgba(162, 167, 170, 0.5);
  }
}

.btn-group-lg{
  width: 100%
}

.vertical-center{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.highlight {
  background-color: white;
  animation-name: blink;
  animation-duration: 1.5s;
}

@keyframes blink {
  0%   {background-color: white;}
  25%  {background-color: yellow;}
  50%  {background-color: white;}
  75% {background-color: yellow;}
  100% {background-color: white;}
}

.small-select{
  padding: 6px 12px;
}

.input-sm{
  padding: 6px 12px !important;
}

.unmapped-fp {
  background-color: #fff0f0!important;
}

.actions-container {
  position: fixed;
  background-color: white;
  border: 1px solid black;
  z-index: 999;
}

.vertical-timeline-element-content {
  margin-left: 40px !important;
}

.feedback {
  background-color : #31B0D5;
  color: white;
  padding: 3px 6px;
  border-radius: 4px;
  border-color: #46b8da;
}

#mybutton {
  z-index: 99999;
  position: fixed;
  bottom: 67px;
  right: 10px;
}

#chat-container {
  z-index: 99999;
  position: fixed;
  bottom: 100px;
  right: 10px;
  width: 350px;
  min-height: 400px;
  max-height: 400px;
  overflow: auto;
  background-color: #fff;
  border: 1px solid black;
  border-radius: 10px;
}
#xray-container {
  z-index: 99999;
  position: fixed;
  bottom: 100px;
  right: 370px;
  width: 200px;
  min-height: 400px;
  max-height: 400px;
  overflow: auto;
  background-color: #fff;
  border: 1px solid black;
  border-radius: 10px;
  padding: 3px;
}