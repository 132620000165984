.results-container {
    position: absolute;
    background-color: white;
    top: 100%;
    padding: 15px;
    width: 600px;
    z-index: 999;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    max-height: 500px;
    overflow-y: auto;
}

.search-results-category {
    font-size: 17px;
    margin-top: 25px;

    &:first-of-type {
        margin-top: 0px !important;
    }
}


.search-result-item-title {
    margin-right: 10px;
    font-size: 20px;
}

.search-result-item-desc {
    display: inline-block;
    color: rgb(100, 98, 98);
    font-size: 14px;
}

.search-separator {
    margin-top: 0;
}

.search-results-item {
    margin-top:5px;
    padding-left: 10px;
    &:hover{
        background-color: whitesmoke;
    }
}

.search-results {
    display: flex;
    justify-content: space-between;
}

.search-results:last-child {
    margin-bottom: 0px;
}

.search-results p {
    margin: 0;
}